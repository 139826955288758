import Vue from "vue"
import moment from "moment"

export function validateRes(res, success){
    const valid = res.code == 200;
    !valid && Vue.prototype.$message.error(res.msg);
    valid && success && Vue.prototype.$message.success(res.msg);
    return valid;
}

export function validateQuestionType(question) {
    const valid = question.type != 1;
    !valid && Vue.prototype.$message.warning("当前题型为问答题");
    return valid;
}

export const formatTime = (time) => moment(time).format('YYYY-MM-DD HH:mm:ss');

export const isDev = process.env.NODE_ENV == "development";

export const getNewQuestion = (robot, index) => {
    return {
        question: robot.question[index].question,
        robot_ask_time: "",
        stu_answer_time: "",
        stu_key_word: "",
        stu_post_time: 0
    }
};

