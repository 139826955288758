<template>
    <div class="fast-reply">
        <div class="title">快捷回复</div>
        <el-input class="search-input" type="text" placeholder="搜索" v-model="searchValue"></el-input>
        <div class="main" @contextmenu.stop.prevent>
            <el-scrollbar style="height: 100%; width: 100%;">
                <div class="scroll-container">
                    <div class="group-item" v-for="(groupItem, groupIndex) in viewList" :key="groupItem.id" @contextmenu.stop="e => openEditReplyMenu(e, groupItem, groupIndex)">
                        <el-popover
                            placement="left"
                            width="300"
                            trigger="manual"
                            v-model="groupItem.dialog"
                            popper-class="custom-popover"
                            @after-leave="clearSaveGroupParams(groupIndex)"
                        >
                            <template #default>
                                <div class="edit-group" tabindex="0" :ref="`groupModal${groupIndex}`">
                                    <div class="dialog-header">
                                        <div class="title">修改分组名称</div>
                                        <i class="iconfont" @click="groupItem.dialog = false;">&#xe608;</i>
                                    </div>
                                    <el-form :model="saveGroupParams" :rules="saveGroupRules" label-width="0px" :ref="`groupForm${groupIndex}`" class="group-form">
                                        <el-form-item prop="name">
                                            <el-input size="small" v-model="saveGroupParams.name"></el-input>
                                        </el-form-item>
                                    </el-form>
                                    <div class="bottom-operation">
                                        <el-button class="custom-btn cancel" size="small" @click="groupItem.dialog = false;">取消</el-button>
                                        <el-button class="custom-btn confirm" type="primary" size="small" @click="confirmEditGroup(groupItem, groupIndex)">确定</el-button>
                                    </div>
                                </div>
                            </template>
                            <div class="header" slot="reference" @click="groupItem.open = !groupItem.open">
                                <i class="icon el-icon-arrow-right" :class="{ open: groupItem.open }"></i>
                                <div class="group-title">{{ groupItem.name }}</div>
                                <div class="count">({{ groupItem.children.length }}/100)</div>
                            </div>
                        </el-popover>
                        <div class="reply-list" :class="{ open: groupItem.open }">
                            <div class="reply-item" v-for="(item, index) in groupItem.children" :key="item.id" @click="setChatMessage(item)" @contextmenu.stop="e => openEditReplyMenu(e, item, index)">
                                <div class="fast-coding">[{{ item.fast_coding }}]</div>
                                <div class="reply-content">
                                    <div class="content" v-html="item.content"></div>
                                    <div class="tooltip" v-html="item.content"></div>
                                </div>
                                <Contextmenu
                                    :index="index" 
                                    :move-up="!!groupItem.children[index - 1]" 
                                    :move-down="!!groupItem.children[index + 1]" 
                                    :visible.sync="item.menu"
                                    :item="item"
                                    @up="(params) => replyOffset('reply', params)"
                                    @down="(params) => replyOffset('reply', params)"
                                    @edit="editReply(item)"
                                    @del="delReply('reply', item)"
                                />
                            </div>
                        </div>
                        <Contextmenu 
                            :index="groupIndex" 
                            :move-up="!!viewList[groupIndex - 1]"
                            :move-down="!!viewList[groupIndex + 1]"
                            :visible.sync="groupItem.menu"
                            :item="groupItem"
                            @up="(params) => replyOffset('group', params)"
                            @down="(params) => replyOffset('group', params)"
                            @edit="editGroup(groupItem, groupIndex)"
                            @del="delReply('group', groupItem)"
                        />
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="bottom-operation">
            <div class="operation-list">
                <el-link class="item" type="success" :underline="false" href="download/phrase.xlsx" download="客服工作台快捷短语.xlsx">下载</el-link>
                <span class="decorate">|</span>
                <el-button class="item" type="text" @click="exportInfo">导出</el-button>
                <span class="decorate">|</span>
                <el-button class="item" type="text" @click="openFile">导入</el-button>
            </div>
            <el-button class="create-reply" type="text" @click="openDialog">新建回复</el-button>
        </div>
        <el-dialog title="提示" :visible.sync="saveReplyDialog" width="640px" @closed="clearSaveParams">
            <el-form :model="saveReplyParams" :rules="saveReplyRules" label-width="88px" status-icon ref="reply">
                <el-form-item label="选择分组" prop="" class="group-form-item" required>
                    <el-form-item prop="group_id">
                        <el-select v-model="saveReplyParams.group_id">
                            <el-option v-for="(item) in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form :model="saveGroupParams" :rules="saveGroupRules" label-width="0px" ref="groupForm" class="group-form">
                        <el-form-item label="" prop="name">
                            <el-input v-if="showGroupInput" v-model="saveGroupParams.name" class="group-input"></el-input>
                            <el-button v-if="!showGroupInput" type="text" class="blue-font" @click="showGroupInput = !showGroupInput">新建分组</el-button>
                            <el-button v-else type="text" class="blue-font" @click="saveGroup">添加</el-button>
                        </el-form-item>
                    </el-form>
                </el-form-item>
                <el-form-item label="添加话术" prop="content">
                    <div class="rich-text-form el-input__inner" tabindex="1">
                        <rich-text :value.sync="saveReplyParams.content" tinymce-id="tinymceReply"></rich-text>
                        <div class="rich-text-expand">
                            <emoji tinymce-id="tinymceReply" style="margin-right: 10px;"></emoji>
                            <rich-text-img :params.sync="saveReplyParams.img" tinymce-id="tinymceReply" :once="true"></rich-text-img>
                        </div>
                    </div>
                    <div class="rich-text-hint">可插入一张图片（要求5M以内）</div>
                </el-form-item>
                <el-form-item label="快捷编码" prop="fast_coding">
                    <el-input v-model="saveReplyParams.fast_coding" placeholder="输入“/#”+关键词或快捷编码，可以进行快捷话术联想" @focus="setDefaultCoding"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="saveReplyDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveReply">确 定</el-button>
            </div>
        </el-dialog>
        <input type="file" accept=".xls,.xlsx" ref="importFile" style="display: none;" @change="importFile">
        <div class="mask" v-if="showMask"></div>
    </div>
</template>

<script>
import RichText from "./common/RichText.vue"
import Emoji from "./common/Emoji.vue"
import RichTextImg from "./common/RichTextImg.vue"
import Contextmenu from "./FastReply/Contextmenu.vue"
import { getGroupListAPI, getReplyDataAPI, delReplyAPI, saveGroupAPI, saveReplyAPI, importReplyAPI, replyOffsetAPI, groupOffsetAPI, delGroupAPI } from "../../../utils/apis"
const getSaveReplyParams = () => ({
    id: void 0,
    group_id: void 0,
    content: "",
    fast_coding: "",
    img: ""
});
const getSaveGroupParams = () => ({
    id: void 0,
    name: ""
})
export default {
    name: 'FastReply',
    components: {
        RichText,
        Emoji,
        RichTextImg,
        Contextmenu
    },
    inject: ["validateRes"],
    props: {
        replyList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        const validateFastCoding = (rule, value, callback) => {
            if(value.replace("/#", "").length <= 0) {
                callback(new Error("请填写快捷编码"))
            }else if(!value.startsWith("/#")) {
                callback(new Error("快捷编码必须以/#开头"))
            }else {
                callback()
            }
        }
        return {
            saveReplyDialog: false,
            saveReplyParams: getSaveReplyParams(),
            saveGroupParams: getSaveGroupParams(),
            saveReplyRules: {
                group_id: [{ required: true, message: "请选择分组", trigger: "change" }],
                content: [{ required: true, message: "请填写内容", trigger: "blur" }],
                fast_coding: [{ validator: validateFastCoding, trigger: "blur" }],
            },
            saveGroupRules: {
                name: [{ required: true, message: "请填写分组名称", trigger: "blur" }],
            },
            showGroupInput: false,
            // replyList: [],
            groupList: [],
            searchValue: ""
        }
    },
    computed: {
        viewList() {
            if(!this.searchValue) return this.replyList;
            return this.replyList.map(groupItem => ({ ...groupItem, children: groupItem.children.filter(item => item.content.includes(this.searchValue)) })).filter(item => item.children.length > 0)
        },
        showMask() {
            return this.replyList.some(item => item.dialog);
        }
    },
    methods: {
        init() {
            this.getReplyList();
        },
        openDialog() {
            this.getGroupList();
            this.saveReplyDialog = true;
        },
        async getReplyList() {
            const res = await getReplyDataAPI();
            if(this.validateRes(res)) {
                const imgReg = /<img class="save-img"(.*)\/>/
                res.data.forEach(groupItem => {
                    groupItem.open = true;
                    groupItem.menu = false;
                    groupItem.dialog = false;
                    groupItem.children.forEach(item => {
                        const imgRes = imgReg.exec(item.content);
                        item.menu = false;
                        if(imgRes) {
                            item.imgTag = imgRes[0];
                            item.content = item.content.replace(imgReg, " [图片] ");
                        }
                    });
                });
                this.$emit("setReplyList", res.data);
            }
        },
        async getGroupList() {
            const res = await getGroupListAPI();
            this.groupList = res.data;
        },
        setChatMessage(item) {
            this.$emit("setChatMessage", item.content.replace("[图片]", item.imgTag));
        },
        saveReply() {
            this.$refs.reply.validate(async (valid) => {
                if(!valid) return void 0;
                const res = await saveReplyAPI(this.saveReplyParams);
                if(this.validateRes(res, true)) {
                    this.getReplyList();
                    this.saveReplyDialog = false;
                }
            })
        },
        async replyOffset(type, params) {
            const request = type == "reply" ? replyOffsetAPI : groupOffsetAPI;
            const res = await request(params);
            if(!this.validateRes(res, true)) return void 0;
            this.getReplyList();
        },
        editReply(item) {
            item.menu = false;
            this.openDialog();
            Object.keys(this.saveReplyParams).forEach(key => this.saveReplyParams[key] = item[key]);
        },
        editGroup(groupItem, groupIndex) {
            this.replyList.forEach(item => item.dialog = false);
            groupItem.dialog = true;
            this.$nextTick(() => {
                Object.keys(this.saveGroupParams).forEach(key => this.saveGroupParams[key] = groupItem[key])
            });
        },
        confirmEditGroup(groupItem, groupIndex) {
            const from = this.$refs[`groupForm${groupIndex}`];
            from && from[0] && from[0].validate(async valid => {
                if(!valid) return void 0;
                const res = await saveGroupAPI(this.saveGroupParams);
                if(!this.validateRes(res, true)) return void 0;
                this.getReplyList();
                groupItem.dialog = false;
            })
        },
        async delReply(type, item) {
            const request = type == "reply" ? delReplyAPI : delGroupAPI;
            item.menu = false;
            const res = await request({ id: item.id });
            if(!this.validateRes(res, true)) return void 0;
            this.getReplyList();
        },
        clearSaveParams() {
            this.$nextTick(() => {
                this.saveReplyParams = getSaveReplyParams();
                this.saveGroupParams = getSaveGroupParams();
                this.showGroupInput = false;
                this.$nextTick(() => {
                    this.$refs.reply.clearValidate();
                    this.$refs.groupForm.clearValidate();
                })
            })
        },
        closeEditGroupDialog(groupItem) {
            groupItem.dialog = false;
        },
        clearSaveGroupParams(groupIndex) {
            this.$nextTick(() => {
                this.saveGroupParams = getSaveGroupParams();
                const target = this.$refs[`groupForm${groupIndex}`];
                target && target[0] && target[0].clearValidate();
            })
        },
        async saveGroup() {
            this.$refs.groupForm.validate(async valid => {
                if(!valid) return void 0;
                const res = await saveGroupAPI(this.saveGroupParams);
                if(!this.validateRes(res, true)) return void 0;
                this.getGroupList();
                this.saveGroupParams.name = "";
            })
        },
        download() {
            location.href = "download/phrase.xlsx"
        },
        async exportInfo() {
            location.href = `terms/export?id=${localStorage.getItem("studentID")}`;
        },
        async importFile(e) {
            const formData = new FormData();
            formData.append("excel", e.target.files[0]);
            const res = await importReplyAPI(formData);
            e.target.value = "";
            if(!this.validateRes(res, true)) return void 0;
            this.getReplyList();
        },
        openFile() {
            this.$refs.importFile.click();
        },
        setDefaultCoding() {
            if(!this.saveReplyParams.fast_coding) {
                this.saveReplyParams.fast_coding = '/#'
            }
        },
        openEditReplyMenu(e, item, index) {
            if(e.button == 2) {
                e.preventDefault();
                this.replyList.forEach(groupItem => {
                    groupItem.menu = false;
                    groupItem.children.forEach(item => item.menu = false)
                })
                item.menu = !item.menu;
            }
        }
    },
    created() {
        this.init();
    }
}
</script>

<style scoped lang="scss">
$themeColor: #006AFF;
.blue-font {
    margin-left: 16px;
    color: $themeColor;
    font-size: 14px;
}
.fast-reply {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 287px;
    min-width: 287px;
    height: 100%;
    margin-right: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #EAECF0;
    border-left: none;
    background-color: #fff;
    overflow: hidden;
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,.3);
        z-index: 9;
    }
    .title {
        box-sizing: border-box;
        height: 68px;
        color: #606266;
        text-align: center;
        line-height: 67px;
        border-bottom: 1px solid #EAECF0;
    }

    .search-input {
        box-sizing: border-box;
        display: flex;
        padding: 10px;
        width: 100%;
        overflow: hidden;
        ::v-deep .el-input__inner {
            flex: 1;
            height: 30px;
        }
    }

    .main {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        overflow: hidden;
        .scroll-container {
            flex: 1;
            box-sizing: border-box;
            padding: 0 10px;
            overflow: hidden;
            .group-item {
                position: relative;
                box-sizing: border-box;
                width: 100%;
                margin-bottom: 10px;
                /* overflow: hidden; */
                &:last-child {
                    margin-bottom: 0;
                }
                
                .header {
                    display: flex;
                    color: #606266;
                    font-size: 14px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    .icon {
                        margin-right: 10px;
                        font-size: 16px;
                        color: #606266;
                        font-weight: 700;
                        transition: transform .3s;
                        &.open {
                            transform: rotate(90deg);
                        }
                    }
                    .group-title {
                        margin-right: 3px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .count {
                        display: flex;
                        align-items: center;
                    }
                }
                .reply-list {
                    box-sizing: border-box;
                    display: none;
                    &.open {
                        display: block;
                    }
                    .reply-item {
                        box-sizing: border-box;
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 32px;
                        padding-left: 20px;
                        background-color: #fff;
                        transition: all .3s;
                        cursor: pointer;
                        &:hover {
                            color: $themeColor;
                            background-color: #F6F7FB;
                        }
                        .fast-coding {
                            margin-right: 4px;
                            color: #006aff;
                        }
                        .reply-content {
                            flex: 1;
                            color: #1E2226;
                            overflow: hidden;
                            .content {
                                width: 100%;
                                line-height: 23px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .tooltip {
                                box-sizing: border-box;
                                display: none;
                                position: absolute;
                                top: 100%;
                                left: 10%;
                                right: 0;
                                width: fit-content;
                                max-width: 80%;
                                padding: 5px 10px;
                                border-radius: 4px;
                                word-wrap: break-word;
                                background-color: #f0edf1;
                                z-index: 99;
                                border: 1px solid #ddd;
                            }
                            &:hover .tooltip {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-operation {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 24px;
        .operation-list {
            display: flex;
            width: 110px;
            justify-content: space-between;
            .item {
                color: #606266;
                transition: color .3s;
                &:hover {
                    color: $themeColor;
                }
            }
            .decorate {
                line-height: 18px;
            }
        }
        .create-reply {
            color: $themeColor;
        }
        ::v-deep .el-button {
            padding: 0;
        }
    }
    .rich-text-form {
        display: flex;
        flex-direction: column;
        /* border: 1px solid #E3E2E5; */
        border-radius: 4px;
        &.el-input__inner {
            padding: 0;
            height: fit-content;
        }
        .rich-text-expand {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            height: 38px;
            padding: 12px 10px 12px 17px;
        }
    }
    .rich-text-hint {
        margin-top: 8px;
        color: #AEB2B9;
        font-size: 12px;
        line-height: 12px;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
        display: flex;
        min-height: 100%;
        .scroll-container {
            min-height: 100%;
        }
    }
}
::v-deep .el-dialog__wrapper {
    .el-dialog__header {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 72px;
        padding: 0 30px;
        border-bottom: 1px solid #E6E7EB;
        .el-dialog__title {
            font-size: 16px;
            color: #606266;
        }
        .el-dialog__headerbtn {
            position: none;
            .el-dialog__close::before {
                font-size: 20px;
            }
        }
    }
    .el-dialog__body {
        padding: 30px !important;
    }
    .el-dialog__footer {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100px;
        padding: 0 20px;
        border-top: 1px solid #E6E7EB;
        .dialog-footer {
            display: flex;
            .el-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 96px;
                height: 40px;
                font-size: 14px;
                word-spacing: -2px;
            }
            .el-button--default {
                color: #606266;
                border-color: #B0B0BA;
                &:hover {
                    color: #fff;
                    background-color: rgba(0, 106, 255, 1);
                    border: none;
                }
            }
            .el-button--primary {
                background-color: #006AFF;
                border: none;
                &:hover {
                    background-color: rgba(0, 106, 255, .8);
                }
            }
        }
    }
}
@keyframes ShowGroup {
    0% {
        width: 0;
    }
    100% {
        width: 180px;
    }
}
::v-deep .group-input {
    width: 0;
    margin-left: 10px;
    animation: ShowGroup .3s ease 0s 1 normal forwards;
}

::v-deep .el-form .el-form-item:last-child {
    margin-bottom: 0;
}
::v-deep p {
    display: flex;
    align-items: center;
    margin: 0 !important;
}
::v-deep .group-form-item {
    .el-form-item__content {
        display: flex;
        .group-form .el-form-item__error {
            margin-left: 10px;
        }
    }
}

.edit-group {
    display: flex;
    flex-direction: column;
    .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        font-size: 14px;
        color: #fff;
        background: linear-gradient(91deg, #006aff 0%, #00B8FF 100%);
        .iconfont {
            font-size: 14px;
            cursor: pointer;
        }
    }
    .group-form {
        padding: 10px 15px;
    }
    .bottom-operation {
        padding: 0 15px 15px;
        text-align: right;
        .confirm {
            background-color: #006aff;
            border-color: #006aff;
        }
    }
}
::v-deep p {
    display: inline;
    margin: 0;
}
</style>
<style>
.custom-popover {
    padding: 0;
    border: none;
}
</style>
